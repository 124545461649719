// -------------------------------------------------------------
//   Homepage
//   Homepage specific styles
// -------------------------------------------------------------
.front {
  .news-events {
    background: rgba(129,129,129,.1);
    position: relative;
    padding-bottom: 75px;

    &:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 140px;
			background-color: #fff;
		}

    .md-width {
      > h2 {
        float: left;
      }

      > p {
        float: right;
        line-height: 1.8;
        @include media-breakpoint-down(md) {
          float: none;
          clear: both;
        }

        a {
          background: url('../images/icon-arrow_green.png') right center no-repeat;
          display: inline-block;
          padding-right: 20px;
          margin-left: 30px;

          @include media-breakpoint-down(md) {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }

      .block-views {
        clear: both;
      }
    }
  }

  .cta,
  .cta-2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
    flex-direction: column;
    position: absolute;
    z-index: 230;
    top: 360px;
    right: 50%;
    margin-right: -540px;
    padding: 0;
    border-radius: 50%;
    width: 108px;
    height: 108px;
    background-color: theme-color("secondary");
    color: #fff !important;
    font-size: 0.7rem;
    line-height: 1.35em;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;
    > * {
      width: 100%;
    }

    &:before {
      content:"";
      display: block;
      margin: 0 auto 5px;
      width: 25px;
      height: 22px;
      background: url('../images/cta.png') no-repeat;
      background-size: 25px 22px;
    }

    &.scrolled-trans {
      position: fixed;
      top: 120px;
      margin-right: 0;
      right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      opacity: 0;
      visibility: hidden;
    }

    &.scrolled {
      opacity: 1;
      visibility: visible;
      @include transition(all .35s);
    }

    @include media-breakpoint-down(lg) {
      margin-right: -465px;
    }
    @include media-breakpoint-down(md) {
      margin-right: -345px;
    }
    @include media-breakpoint-down(sm) {
      margin-right: -255px;
    }
    @include media-breakpoint-down(xs) {
      top: 120px;
      right: 0;
      margin-right: 0;
      border-radius: 50px 0 0 50px;
      width: 110px;
      height: 72px;
    }
  }
  .cta-2 {
    margin-right: -410px;
    @include media-breakpoint-down(lg) {
      margin-right: -340px;
    }
    @include media-breakpoint-down(md) {
      margin-right: -220px;
    }
    @include media-breakpoint-down(sm) {
      top: 240px;
      margin-right: -255px;
    }
    @include media-breakpoint-down(xs) {
      top: 240px;
      margin-right: 0;

    }
    &:before {
      background-image: url('../images/cta2.png');
    }
    &.scrolled-trans {
      top: 240px;
    }
  }

  p img {
    max-width: 100%;
    height: auto!important;
  }
}
