.page-agenda {
	.view-agenda {
		position: relative;
		margin-bottom: -85px;
		padding-top: 40px;
		padding-bottom: 115px;
		background-color: rgba(129,129,129,.1);
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 140px;
			background-color: #fff;
		}
		.pager {
			margin-top: 30px;
		}
	}
}

.node-type-event {
	.date {
		margin: 0 0 15px;
		color: theme-color("lightblue");
		font-size: 1.5rem;
		line-height: 1em;
	}
	.lieu {
		margin: 0 0 20px;
		color: #cacbcb;
		font-size: 0.7rem;
		line-height: normal;
	}
}