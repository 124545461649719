// -------------------------------------------------------------
//   Background
//   full-size background image
// -------------------------------------------------------------
.bg {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
  background: theme-color('secondary');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
