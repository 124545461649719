// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
	margin: 0 0 20px;
	color: theme-color("lightblue");
	line-height: 1.5em;
	font-family: inherit;
	font-weight: 700;
	text-transform: uppercase;
	&:last-child {
		margin-bottom: 0;
	}
}
h1,.h1,
h2,.h2 {
	color: theme-color("gray");
	letter-spacing: 2px;
}
h1,.h1 {
	font-size: 1.5rem;
  &.lined {
		&:after {
			content: "";
			display: block;
			margin: 20px 0 0;
			width: 30px;
			height: 1px;
			background-color: theme-color("secondary");
		}
	}
}
h2,.h2 {
	font-size: 1.125rem;
	&.lined {
		&:after {
			content: "";
			display: block;
			margin: 20px 0 0;
			width: 30px;
			height: 1px;
			background-color: theme-color("secondary");
		}
	}
}
h3,.h3 {
	font-size: 1rem;
	text-transform: none;
}
h4,.h4 {
	font-size: 0.875rem;
}
h5,.h5 {
	font-size: 0.75rem;
}
h6,.h6 {
	font-size: 0.625rem;
}

p, ul, ol {
	margin: 0 0 30px;
	&:last-child {
		margin-bottom: 0;
	}
}

a:not([class*="btn"]) {
	color: theme-color("secondary");
	font-weight: 700;
	text-decoration: none;
	&:hover {
		color: theme-color("secondary");
		text-decoration: underline;
	}
}

.evidence {
  margin: 20px 0;
  padding: 20px 0;
  background: map-get($theme-colors, primary);
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
