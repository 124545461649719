// -------------------------------------------------------------
//   Paragraphs item galerie
// -------------------------------------------------------------

.paragraphs-galerie {
  position: relative;
  padding-top: 75px;
  padding-bottom: 60px;
  .wrapper {
    position: relative;
    z-index: 5;
  }
  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 130px;
    left: 0;
    background-color: rgba(129,129,129,.1);
  }
  .owl-nav {
    margin: 0;
    button.owl-next, button.owl-prev {
      display: block;
      position: absolute;
      top: 50%;
      margin: -24px 0 0;
      border-radius: 50%;
      padding: 0;
      width: 48px;
      height: 48px;
      background-color: #fff !important;
      background-image: url(../images/icon-arrow_green.png) !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: 12px auto !important;
      font-size: 0;
      line-height: 0;
      @include transition(all .35s);
      &:hover {
        background-color: theme-color("secondary") !important;
        background-image: url(../images/icon-arrow_white.png) !important;
      }
    }
  }
  .owl-prev {
    left: -70px;
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .owl-next {
    right: -70px;
  }
}