// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

body:not([class*="cke_editable"]) {
  padding-top: 154px;
	background-color: #fff;
	color: theme-color("gray");
	font-size: 1rem;
  font-weight: 400;
	line-height: 1.875em;
  &.front {
    @include media-breakpoint-down(lg) {
      padding-top: 85px;
    }
  }
}

.row {
  @include media-breakpoint-down(xs) {
    display: block;
  }
}

.container {
  .md {
    @include media-breakpoint-up(xl) {
      max-width: 1075px;
    }
  }
  .md-width {
    margin-left: auto;
    margin-right: auto;
    max-width: 1045px;
  }
}

body.page-user, body.page-search404, body.page-search, body.page-sitemap, body.node-type-webform {
  #main {
    .main-wrapper {
      @extend .container;
      padding-top: 30px;
      padding-bottom: 30px;
      @include media-breakpoint-up(xl) {
        max-width: 1075px;
      }
    }
  }
}
body.page-search404, body.page-search {
  .container-inline div, .container-inline label {
    display: block;
  }
  #search-form {
    margin: 0 0 30px;
  }
  .search-results {
    li {
      & + li {
        margin-top: 30px;
      }
    }
  }
}

.iframe-embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  padding-top: percentage(9 / 16);
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.main-wrapper {
  position: relative;
}

.os-animation, .staggered-animation {
  opacity: 0;
  &.animated {
    opacity: 1;
  }
}