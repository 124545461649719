// -------------------------------------------------------------
//   Paragraphs item texte
// -------------------------------------------------------------

.paragraphs-texte, .intro {
	padding-top: 30px;
	padding-bottom: 30px;
	h2, .h2 {
		@extend .lined;
	}
	.row {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
		.col-md-6 {
			&:first-child {
				padding-right: 40px;
				@include media-breakpoint-down(md) {
					padding-right: 15px;
				}

				@include media-breakpoint-down(sm) {
					margin-bottom: 30px;
				}
			}
			&:last-child {
				padding-left: 40px;
				@include media-breakpoint-down(md) {
					padding-left: 15px;
				}
			}
		}
	}
	img {
		max-width: 100%;
    	height: auto !important;
	}
}

.intro {
	background-image: url(../images/grass.png);
	background-position: left bottom;
	background-repeat: no-repeat;
  	padding: 90px 0;
  	@include media-breakpoint-down(sm) {
		background-image: none;
	}
  	 .md-width {
      padding-left: 230px;
      @include media-breakpoint-down(lg) {
			padding-left: 300px;
		}
		@include media-breakpoint-down(sm) {
			padding-left: 0;
		}
    }

  a {
    background: url('../images/icon-arrow_green.png') right center no-repeat;
    display: inline-block;
    padding-right: 20px;
  }
}
