.page-actualites {
	.view-news {
		position: relative;
		margin-bottom: -55px;
		padding-top: 40px;
		padding-bottom: 85px;
		background-color: rgba(129,129,129,.1);
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 140px;
			background-color: #fff;
		}
		.pager {
			margin-top: 30px;
		}
	}
}

.node-type-news {
	.date {
		margin: 0;
		color: #cacbcb;
		font-size: 0.7rem;
		font-weight: 700;
	}
}