// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

.main-header {
	position: fixed;
	z-index: 500;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #fff;
	@include transition(all .35s);
	.inner {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-ms-flex-align: end!important;
		align-items: flex-end!important;
		-ms-flex-pack: justify!important;
    	justify-content: space-between!important;

    	position: relative;
	}
	.logo {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;

		margin-left: 30px;
		padding: 30px 20px;
		height: 154px;
		overflow: hidden;
		@include transition(all .35s);
		img {
			width: 62px;
		}

    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
	}

	.block-search {
    @include media-breakpoint-down(lg) {
      background: #fff;
      margin-top: 20px;
    }

		form {
			width: 200px;
			max-width: 100%;
			font-size: 0;
		}
		.form-item, .form-actions {
			display: inline-block;
			vertical-align: middle;
			margin: 0;
		}
		.form-item {
			width: calc(100% - 42px);
		}
		.form-text {
			display: block;
			margin: 0;
        	border: 0;
        	padding: 0 15px;
        	width: 100%;
			height: 42px;
			background-color: transparent;
			outline: 0;
			box-shadow: none;
        	color: #fff;
        	font-size: 0.625rem;
        	font-weight: 700;
        	line-height: normal;
        	text-transform: uppercase;
        	@include transition(all .2s);
        	&::placeholder {
			    color: theme-color("lightprimary");
			  }
		}
		input[type=submit] {
			display: block;
			margin: 0;
	    	border: 0;
	    	padding: 0;
	    	width: 42px;
	    	height: 42px;
	    	background-color:transparent;
	    	background-image: url(../images/icon-search.png);
	    	background-position: center;
	    	background-repeat: no-repeat;
	    	background-size: 10px auto;
	    	@include transition(all .2s);

        @include media-breakpoint-down(lg) {
          background-image: url(../images/icon-search_green.png);
        }
		}
	}

	&.headroom--not-top {
		border-bottom: 1px solid theme-color("lightblue");
	}
	@include media-breakpoint-up(xl) {
		&.headroom--not-top:not([class*="headroom--pinned"]) {
			.logo {
				padding-top: 10px;
				padding-bottom: 10px;
				height: 82px;
			}

			.secondary-nav {
				-ms-transform: translateY(-50px);
				-webkit-transform: translateY(-50px);
				transform: translateY(-50px);
			}
		}
	}
	@include media-breakpoint-down(lg) {
		&.headroom--not-top {
			.logo {
				padding-top: 10px;
				padding-bottom: 10px;
				height: 82px;
			}
		}
	}
}

body.front {
	.main-header {
		.logo {
			position: relative;
			top: 75px;
			margin-left: 0;
			padding: 25px 40px;
			background-color: #fff;
			@include media-breakpoint-down(lg) {
	          margin-top: -75px;
	        }
		}
		&.headroom--not-top {
			.logo {
				top: 0;
				padding-top: 10px;
				padding-bottom: 10px;
				@include media-breakpoint-down(lg) {
		          margin-top: 0;
		        }
			}
		}
	}
}