// -------------------------------------------------------------
//   Navigation
// -------------------------------------------------------------
#nav {
    @include media-breakpoint-up(xl) {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .main-nav {
        > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 0;

            > li {
                display: inline-block;
                position: relative;

                @include media-breakpoint-down(lg) {
                    display: block;
                }
                > a, > .nolink {
                    display: block;
                    border-bottom: 2px solid transparent;
                    padding: 27px 0 25px;
                    color: theme-color("gray");
                    font-size: 0.75rem;
                    font-weight: 700;
                    line-height: 1.5em;
                    text-decoration: none;
                    text-transform: uppercase;
                    cursor: pointer;
                    @include transition(all .2s);

                    @include media-breakpoint-down(lg) {
                        border-bottom: 0;
                        padding: 21px 15px;
                        color: #fff;
                    }
                }
                & + li {
                    margin-left: 20px;

                    @include media-breakpoint-down(lg) {
                        margin-left: 0;
                    }
                }

                &:hover, &.active, &.active-trail {
                    > a, > .nolink {
                        border-color: theme-color("lightblue");
                        color: theme-color("lightblue");

                        @include media-breakpoint-down(lg) {
                            background-color: theme-color("secondary");
                            color: #fff;
                        }
                    }
                }

                @include media-breakpoint-up(xl) {
                    &:hover, &:focus {
                        > ul {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                // Niv 2
                > ul {
                    position: absolute;
                    z-index: 1000;
                    top: 100%;
                    left: 0;
                    margin: 0;
                    padding: 0;
                    width: 270px;
                    min-width: 100%;
                    background-color: theme-color("primary");
                    list-style: none;
                    text-align: left;                    
                    @include transition(all .35s);

                    @include media-breakpoint-up(xl) {
                        opacity: 0;
                        visibility: hidden;
                    }

                    @include media-breakpoint-down(lg) {
                        display: none;
                        position: relative;
                        top: auto;
                        left: auto;
                        width: 100%;
                        background-color: transparentize(#fff,0.8);
                        text-align: center;
                    }
                    > li {
                        position: relative;
                        > a, > .nolink {
                            display: block;
                            padding: 10px 15px;
                            color: #fff;
                            font-size: 0.9rem;
                            font-weight: 700;
                            line-height: 1.3em;
                            text-decoration: none;
                            cursor: pointer;
                            @include transition(all .2s);
                            @include media-breakpoint-down(lg) {
                                padding-top: 15px;
                                padding-bottom: 15px;
                            }
                        }
                        & + li {
                            border-top: 1px solid transparentize(#fff,0.85);
                            @include media-breakpoint-down(lg) {
                                border-top: 0;
                            }
                        }
                        &:hover, &.active {
                            > a, > .nolink {
                                background-color: darken(theme-color("primary"), 10%);
                            }
                        }

                        @include media-breakpoint-up(xl) {
                            &:hover, &:focus {
                                > ul {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }

                        // NIV 3
                        > ul {
                          position: absolute;
                          z-index: 1000;
                          top: 0;
                          left: 100%;
                          margin: 0;
                          padding: 0;
                          width: 270px;
                          background-color: lighten(theme-color("primary"), 10%);
                          list-style: none;
                          text-align: left;
                          @include transition(all .35s);

                          @include media-breakpoint-up(xl) {
                                opacity: 0;
                                visibility: hidden;
                            }

                          @include media-breakpoint-down(lg) {
                              display: none;
                              position: relative;
                              top: auto;
                              left: auto;
                              width: 100%;
                              background-color: transparentize(#fff,0.8);
                              text-align: center;
                          }

                          > li {
                            > a, > .nolink {
                                display: block;
                                padding: 5px 15px;
                                color: #fff;
                                font-size: 0.75rem;
                                line-height: 1.5em;
                                text-decoration: none;
                                @include transition(all .2s);

                                @include media-breakpoint-down(lg) {
                                    padding-top: 15px;
                                    padding-bottom: 15px;
                                }
                            }
                            & + li {
                                border-top: 1px solid transparentize(#fff,0.85);
                                @include media-breakpoint-down(lg) {
                                    border-top: 0;
                                }
                            }
                            &:hover, &.active {
                                > a, > .nolink {
                                    background-color: darken(theme-color("primary"), 10%);
                                }
                            }
                          }
                        }
                    }
                }
                &:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3) {
                    > ul {
                        > li {
                            > ul {
                                left: auto;
                                right: 100%;
                                @include media-breakpoint-down(lg) {
                                    right: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
        @include media-breakpoint-down(lg) {
            .sublvl {
                > li {
                    &.expanded {
                        position: relative;
                        overflow: hidden;
                    }
                    .expand {
                        display: block;
                        position: absolute;
                        z-index: 5;
                        top: 0;
                        right: 0;
                        width: 60px;
                        height: 60px;
                        background-color: transparentize(#fff,0.9);
                        &:before {
                            content: '+';
                            display: block;
                            position: absolute;
                            top: 50%;
                            margin-top: -12px;
                            width: 100%;
                            color: #fff;
                            font-size: 24px;
                            line-height: 1em;
                            text-align: center;
                        }
                    }
                    > ul {
                        > li {
                            > .expand {
                                width: 48px;
                                height: 48px;
                            }
                        }
                    }
                    &.open {
                        > .expand {
                            &:before {
                                content: '-';
                            }
                        }
                        > ul {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .secondary-nav {
        @include media-breakpoint-up(xl) {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-align: center!important;
            align-items: center!important;

            position: absolute;
            top: 0;
            right: 0;
            padding: 0 20px;
            background-color: theme-color("primary");
        }
        @include transition(all .35s);

        @include media-breakpoint-down(lg) {
            margin-top: 60px;
        }

        > * {
            @include media-breakpoint-up(xl) {
                -ms-flex: 0 0 auto;
                flex: 0 0 auto;
                width: auto;
                max-width: none;
            }

            @include media-breakpoint-down(lg) {
                display: inline-block;
            }
        }
        > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            font-size: 0;

            @include media-breakpoint-down(lg) {
                width: 100%;
            }

            > li {
                display: inline-block;
                position: relative;

                @include media-breakpoint-down(lg) {
                    display: block;
                }
                > a, > .nolink {
                    display: block;
                    padding: 15px 10px;
                    color: #fff;
                    font-size: 0.625rem;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration: none;
                    text-transform: uppercase;
                    @include transition(all .2s);

                    @include media-breakpoint-down(lg) {
                        padding: 10px;
                    }
                }

                &:hover, &.active, &.active-trail {
                    > a, > .nolink {
                        background-color: theme-color("lightprimary");
                    }
                }
            }
        }
    }
}


.trigger-menu {
    position: relative;
    z-index: 10;
  margin: 30px 20px;
  width: 40px;
  height: 25px;
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  @include transition(all .2s ease-in-out);
  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 5px;
    background: theme-color("primary");
    border-radius: 5px;
    opacity: 1;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    @include transition(all .2s ease-in-out);
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 20px;
    }
  }

  &.open {
    /* top: -75px; */
    span {
        background: #fff;
      &:nth-child(1) {
        top: 10px;
        -ms-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &:nth-child(3) {
        top: 10px;
        -ms-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
}

@include media-breakpoint-down(lg) {
    nav#nav {
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 90px 0;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        background-color: transparentize(map-get($theme-colors, primary),0.02);
        overflow-x: auto;
        overflow-y: scroll;
        @include transition(all .35s);
    }
}

body.open-menu {
    @include media-breakpoint-down(lg) {
        height: 100%;
        overflow: hidden;
        nav#nav {
            visibility: visible;
            opacity: 1;
        }
    }
}
