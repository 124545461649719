// Breadcrumb
// =============================================================================

.breadcrumb {
	color: #fff;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.7em;
	a {
		color: #fff !important;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}