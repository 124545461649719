// Variables
//


//
// Color system
//

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":        #2a316d,
    "secondary":      #9aca58,
    "lightprimary":   #737698,
    "lightsecondary": #6696cb,
    "lightblue":      #558bc5,
    "gray":           #505352,
    "success":        #28a745,
    "info":           #17a2b8,
    "warning":        #ffc107,
    "danger":         #dc3545,
    "light":          #f8f9fa,
    "dark":           #343a40
  ),
  $theme-colors
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Montserrat', sans-serif;

$use-all                :   true; // all animations status