// -------------------------------------------------------------
//   Paragraphs item cta
// -------------------------------------------------------------

.paragraphs-cta, .infos {
	padding-top: 45px;
	padding-bottom: 65px;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1.7em;
	text-align: center;
	.inner {
		padding: 35px 15px 40px;
		border: 1px solid theme-color("lightblue");
	}
	h2, .h2 {
		margin-bottom: 15px;
		color: theme-color("lightblue");
		&:last-child {
			margin-bottom: 0;
		}
	}
	p, ul, ol, .field-name-field-content-texte {
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.infos {
  text-align: left;

  .inner {
    padding: 50px;
  }
}
