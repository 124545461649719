.pager {
	margin: 15px 0 0;
	padding: 0;
	list-style: none;
	font-size: 0;
	li {
		display: inline-block;
		vertical-align: middle;
		margin: 0;
		border: 2px solid transparentize(theme-color("gray"), .8);
		border-radius: 50%;
		width: 42px;
		height: 42px;
		color: #919191;
		font-size: 0.875rem;
		font-weight: 700;
		line-height: 38px;
		text-align: center;
		a {
			display: block;
			color: #919191;
			text-decoration: none !important;
			@include transition(all .35s);
		}
		& + li {
			margin-left: 4px;
		}
		&.pager-current {
			color: theme-color("secondary");
		}
		&:hover {
			a {
				color: theme-color("secondary");
			}
		}
		&.pager-first, &.pager-previous, &.pager-next, &.pager-last {
			background-color: #fff;
			border-color: #fff;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 12px auto;
			font-size: 0;
			@include transition(all .35s);
			&:hover {
				border-color: theme-color("secondary");
				background-color: theme-color("secondary");
			}
		}
		&.pager-first, &.pager-last {
			display: none;
		}
		&.pager-previous, &.pager-next {
			background-image: url(../images/icon-arrow_green.png);
			&:hover {
				background-image: url(../images/icon-arrow_white.png);
			}
		}
		&.pager-previous {
			-ms-transform: rotate(180deg);
		    -webkit-transform: rotate(180deg);
		    transform: rotate(180deg);
		}
	}
}