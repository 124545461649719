.newsletter {
  display: none;
	padding-bottom: 75px;
	.newsletter-wrapper {
		position: relative;
		margin-bottom: 85px;
		width: 100%;
		-ms-flex: 0 0 78.375%;
   flex: 0 0 78.375%;
   max-width: 78.375%;

   @include media-breakpoint-down(xs) {
    -ms-flex: 0 0 85%;
    flex: 0 0 85%;
    max-width: 85%;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc((100vw - ((100vw - 1110px)/ 2)) - 21.625%);
    background-color: theme-color("primary");
    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.4);
  }
}
.inner {
  position: relative;
  z-index: 5;
  padding: 120px 30px;
  display: flex;
  flex-flow: row wrap;

}
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
  color: #fff;
}

h2 {
  font-size: 18px;
  width: 100%;
}

p {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: .6rem 2rem 0 0;
}
.logo {
  -ms-flex: 0 0 21.625%;
  flex: 0 0 21.625%;
  max-width: 21.625%;
  text-align: center;
  img {
   margin-left: 20px;
 }
}

#block-mailchimp-newsletter-mailchimp-newsletter {
  flex-grow: 1;
}

#mailchimp-newsletter-form {

  > div {
    width: 100%;

    display: inline-block;
    .form-item {
      float: left;

      & + .form-item {
        margin-left: 2rem;
      }
    }
  }
  input {
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, .3);
    max-width: 150px;
    width: 100%;
    font-size: 14px;
    padding-left: 0;
    outline: none;

    &::placeholder {
      color: #737698;
    }
  }
  #edit-mailchimp-newsletter-submit {
    border: none;
    border-radius: 0;
    position: relative;
    text-align: left;
    // text-indent: -99999px;
    // width: 17px;
    // overflow: hidden;
    // background: url('../images/mail-icon.png');
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: center;

@include media-breakpoint-up(lg) {
  margin-left: 2rem;
}
  }
}
}
