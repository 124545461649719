
// Button
// =============================================================================

.btn {
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 15px 30px;
  padding-right: 50px;
  background-image: url(../images/icon-arrow_white.png);
  background-position: right 25px center;
  background-repeat: no-repeat;
  background-size: 12px auto;
  box-shadow: none !important;
  font-family: $font-family-sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.3em;
  white-space: normal;
  text-decoration: none !important;
  @include transition(all .35s);
  &.btn-primary, &.btn-default, &.btn1 {
    background-color: theme-color("lightblue");
    border-color: theme-color("lightblue");
    color: #fff;
    &:hover {
      background-color: darken(theme-color("lightblue"), 10%);
      border-color: darken(theme-color("lightblue"), 10%);
      color: #fff;
    }
  }
  &.btn-secondary, &.btn-border, &.btn2 {
    background-color: theme-color("primary");
    border-color: theme-color("primary");
    color: #fff;
    &:hover {
      background-color: darken(theme-color("primary"), 10%);
      border-color: darken(theme-color("primary"), 10%);
      color: #fff;
    }
  }
}

input[type="submit"] {
  @extend .btn;
  @extend .btn-primary;
  cursor: pointer;
}
