// -------------------------------------------------------------
//   Banner
// -------------------------------------------------------------

.banner {
	position: relative;
	padding: 60px 0;
	background-color: #000;
	.title {
		position: relative;
		z-index: 5;
	}
	.inner {
		display: inline-block;
		padding: 30px 45px 40px;
		background-color: transparentize(theme-color("primary"), .25);
	}
	h1, .h1 {
		margin-bottom: 0;
		color: #fff;
	}
	.breadcrumb {
		margin-top: 5px;
	}
}