.leaderboard {
	.swiper-container {
	    width: 100%;
	    height: 510px;
	}

  .title {
		position: absolute;
		z-index: 5;
    bottom: 50px;
    left: 0;
    width: 100%;
    font-size: 0.875rem;
	}
	.inner {
		display: inline-block;
		padding: 30px 45px 40px;
		background-color: transparentize(theme-color("primary"), .25);
    color: #fff;
    max-width: 340px;

    a {
      color: #fff;
      display: block;
      margin-top: 10px;
    }
	}
}
