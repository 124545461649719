// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

.main-footer {
	position: relative;
	background-color: theme-color("lightblue");
	color: #fff;
	font-size: 0.875rem;
	line-height: 2.15em;
	.wrapper {
		position: relative;
		z-index: 5;
	}
	.col-footer {
		padding: 65px 30px 60px;
	}
	h2,.h2,
	h3,.h3,
	h4,.h4,
	h5,.h5,
	h6,.h6 {
		margin-bottom: 0;
		color: #fff;
		line-height: 2.15em;
	}
	p {
		margin: 0 0 35px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	a:not([class*="btn"]) {
		color: #fff !important;
	}
	.menu {
		padding-left: 80px;
		padding-right: 80px;
		background-color: theme-color("lightsecondary");

    @include media-breakpoint-down(xs) {
      padding-left: 30px;
      padding-right: 30px;
    }

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				a {
					font-size: 0.875rem;
					line-height: 2.15em;
					text-transform: uppercase;
				}
				& + li {
					margin-top: 5px;
				}
			}
		}
		p {
			margin-top: 15px;
			font-size: 0.55rem;
			line-height: 1.6em;
			a:not([class*="btn"]) {
				font-weight: 400;
			}
		}
	}
	#map {
		position: absolute;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 57.5%;
		background-image: url(../images/map.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: auto 100%;
		@include media-breakpoint-down(lg) {
			left: 65%
		}

	    @include media-breakpoint-down(md) {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			height: 300px;
		}
	}
}
