.item--event {
	display: block;
	margin: 10px 0;
	text-decoration: none !important;
	.img-wrapper {
		overflow: hidden;
		img {
			width: 100%;
			@include transition(all .35s);
		}
	}
	.content {
		padding: 40px 35px 35px;
		background-color: #fff;
    min-height: 215px;
		.date {
			margin: 0 0 15px;
			color: theme-color("lightblue");
			font-size: 1.425rem;
			line-height: 1em;
      font-weight: 400;
		}
		h3 {
			margin: 0 0 5px;
			color: theme-color("gray");
			font-size: 0.875rem;
			line-height: 1.7em;
		}
		.lieu {
			margin: 0 0 20px;
			color: #cacbcb;
			font-size: 0.7rem;
			line-height: normal;
		}
		.more {
			margin: 0;
			color: theme-color("secondary");
			font-size: 0.875rem;
			font-weight: 700;
			line-height: normal;
			@include transition(all .35s);
		}
	}
	&:hover {
		.img-wrapper {
			img {
				-ms-transform: scale(1.1);
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
		.more {
			text-decoration: underline;
		}
	}
}
