
.view-id-news {

  .view-content .row {
    display: inline-block;

    > div {
      width: 100%;

      @include media-breakpoint-up(md) {
        float: left;
        width: calc(33.3333333333% - 1.4rem);

        & + div {
          margin-left: 2rem;
        }
      }

    }
  }

  .item--news {
    display: block;
    margin: 10px 0;
    text-decoration: none !important;
    height: 100%;

    .content {
      padding: 40px 30px 35px;
      background-color: #fff;
      color: theme-color("gray");
      font-size: 0.875rem;
      line-height: 1.7em;
      height: 100%;
      min-height: 268px;
      display: flex;
      flex-flow: column wrap;

      p {
       margin: 0 0 15px;
       font-weight: normal;
       &.more {
        font-weight: bold;
      }
      &:nth-child(3){
        flex-grow: 1;
      }
    }
    .date {
     margin: 0;
     color: #cacbcb;
     font-size: 0.7rem;
     font-weight: 700;
   }
   h3 {
     margin: 0 0 5px;
     color: theme-color("gray");
     font-size: 0.875rem;
     line-height: 1.7em;
   }
   .more {
     margin: 0;
     color: theme-color("secondary");
     font-size: 0.875rem;
     font-weight: 700;
     @include transition(all .35s);
   }
 }
 &:hover {
  .more {
   text-decoration: underline;
 }
}
}
}
