.paragraphs-evidence, .item--blog, .commune {
	position: relative;
	padding: 30px 0;
	overflow: hidden;
	.wrapper {
		position: relative;
		z-index: 5;
		padding-top: 125px;
		@include media-breakpoint-down(sm) {
			padding-top: 0;
		}
	}
	.content {
		z-index: 5;
		margin-bottom: 70px;
		padding: 45px 65px 50px;
		background-color: theme-color("lightblue");
		box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.4);
		color: #fff;
		font-size: 0.875rem;
		line-height: 1.4em;
		@include media-breakpoint-down(md) {
			padding-left: 30px;
			padding-right: 30px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}
		h2,.h2,
		h3,.h3,
		h4,.h4,
		h5,.h5,
		h6,.h6,
		a {
			color: #fff;
		}
		h2,.h2,
		h3,.h3,
		h4,.h4,
		h5,.h5,
		h6,.h6 {
			margin-bottom: 25px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		p, ul, ol, .field-name-field-content-texte {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.image {
		z-index: 0;
		@include media-breakpoint-down(sm) {
			img {
				width: 100%;
			}
		}
	}
	.bg-stars {
		position: absolute;
		z-index: 0;
		top: 0;
		bottom: 125px;
		width: calc((100vw - ((100vw - 1140px)/ 2)) - 28%);
		background-image: url(../images/bg-stars.jpg);
		@include media-breakpoint-down(lg) {
			width: calc((100vw - ((100vw - 960px)/ 2)) - 28%);
		}
		@include media-breakpoint-down(md) {
			width: calc((100vw - ((100vw - 720px)/ 2)) - 28%);
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	&.pos_right {
		.content {
			@include media-breakpoint-up(md) {
				right: -65px;
			}
		}
		.image {
			@include media-breakpoint-up(md) {
				left: -55px;
			}
		}
		.bg-stars {
			right: 28%;
		}
	}
	&.pos_left {
		.content {
			@include media-breakpoint-up(md) {
				-ms-flex-order: 12;
				order: 12;

				left: -65px;
			}
		}
		.image {
			@include media-breakpoint-up(md) {
				-ms-flex-order: 1;
				order: 1;

				right: -55px;
				text-align: right;
			}
		}
		.bg-stars {
			left: 28%;
		}
	}
}
.item--blog {
	.content {
		@include media-breakpoint-up(md) {
			right: -65px;
		}
	}
	.image {
		@include media-breakpoint-up(md) {
			left: -55px;
		}
	}
	.bg-stars {
		right: 28%;
	}
	& + .item--blog {
		.content {
			@include media-breakpoint-up(md) {
				-ms-flex-order: 12;
				order: 12;

				left: -65px;
				right: auto;
			}
		}
		.image {
			@include media-breakpoint-up(md) {
				-ms-flex-order: 1;
				order: 1;
				
				left: auto;
				right: -55px;
				text-align: right;
			}
		}
		.bg-stars {
			left: 28%;
		}
	}
}
.commune {
	padding-top: 0;
	padding-bottom: 90px;
	@include media-breakpoint-down(sm) {
		padding-top: 60px;
	}
	.wrapper {
		@include media-breakpoint-up(md) {
			padding-top: 155px;
		}
	}
	.content {
		@include media-breakpoint-up(md) {
			margin-bottom: 85px;
			right: -75px;
			padding: 60px 80px;
		}
	}
	.image {
		@include media-breakpoint-up(md) {
			left: -65px;
		}
	}
	.bg-stars {
		bottom: 155px;
		right: 23.5%;
		width: calc((100vw - ((100vw - 1140px)/ 2)) - 23.5%);
		@include media-breakpoint-down(lg) {
			width: calc((100vw - ((100vw - 960px)/ 2)) - 23.5%);
		}
		@include media-breakpoint-down(md) {
			width: calc((100vw - ((100vw - 720px)/ 2)) - 23.5%);
		}
	}
}